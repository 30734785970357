import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { createRef, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { Button, ConditionalWrapper, MobileBasket } from '@components';
import BasketAction from '@components/modules/Basket/BasketAction';
import { useBasket, useBasketMethods, useMediaQuery } from '@hooks';
import {
  selectBranding,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { pushCheckoutStepGTMEvent } from '@utils/gtm';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';
import { up } from '@utils/screens';

import {
  changeStep,
  prevStep,
  selectTabs,
  setIsAnimating,
  setTabsInitialState,
  updateSteps,
} from './orderTabsSlice';
import Tab from './Tab';
import Underline from './Underline';

const BasketPay = dynamic(() => import('@components/modules/Basket/BasketPay'));

const OrderTabs = ({ tabs = [], panels = [] }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isPrevDisabled,
    currentStepIndex,
    steps,
    isAnimating,
    isSetInititalState,
  } = useSelector(selectTabs);
  const basketQuery = useBasket();
  const isUpMd = useMediaQuery(up('md'), true);
  const isUpXl = useMediaQuery(up('xl'), true);
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const { name: brandName } = useAppConfigSelector(selectBranding);
  const {
    basketQuery: { data: { price = {} } = {} },
  } = useBasketMethods();

  useEffect(() => {
    if (!isSetInititalState) {
      const tabsWithValid = tabs.map(tab => ({
        ...tab,
        isValid: false,
      }));

      dispatch(setTabsInitialState(tabsWithValid));
    }
  }, [isSetInititalState]);

  useEffect(() => {
    dispatch(updateSteps(tabs));
  }, [JSON.stringify(tabs)]);

  useEffect(() => {
    pushCheckoutStepGTMEvent({
      step: currentStepIndex + 1,
      brandName,
      cartItems: basketQuery?.data?.rows ?? [],
    });
  }, [currentStepIndex]);

  const currentStep = steps[currentStepIndex] ?? {};
  const isAuthStep = currentStep?.slug === 'autoryzacja';
  const isSummaryStep = currentStep?.slug === 'podsumowanie';

  const isFirstStep = currentStepIndex === 0;
  const stepRefs = steps.reduce((acc, _, index) => {
    acc[index] = createRef();
    return acc;
  }, {});

  const scrollToTop = () => window.scrollTo({ top: 0 });
  const handleTab = tabIndex => {
    dispatch(changeStep(tabIndex));
    scrollToTop();
  };

  const handlePrevTab = () => {
    dispatch(prevStep());
    scrollToTop();
  };

  const showMobileBasket = isFirstStep && !isUpXl;
  const showBasketAction = !isFirstStep && !isUpXl && !isAuthStep;
  const showBasketPay = !isUpXl && isSummaryStep;
  const showPreviuos = !isFirstStep && !isUpXl;

  const hasOnlyStickyBasketAction =
    showBasketAction && !showMobileBasket && !showBasketPay;
  const hasStickyElements =
    showMobileBasket || showBasketAction || showBasketPay;

  return (
    <Fragment>
      <div>
        <div tw="bg-white rounded-md shadow-lg mb-12 relative flex-nowrap overflow-auto">
          <ul role="tablist" aria-orientation="horizontal" tw="flex">
            {steps.map(({ label }, index) => {
              const prevTabIsValid = steps[index - 1]?.isValid ?? false;
              const currentTabIsValid = steps[index].isValid;
              const isActive = currentStepIndex === index;
              const currentTabIsDisabled = prevTabIsValid
                ? !prevTabIsValid
                : !currentTabIsValid;

              return (
                <Tab
                  key={index}
                  disabled={currentTabIsDisabled}
                  isActive={isActive}
                  ref={stepRefs[index]}
                  onClick={() => handleTab(index)}
                >
                  <span tw="text-xl md:text-3xl">{index + 1}</span>
                  {(isUpMd || isActive) && (
                    <span tw="uppercase text-sm pl-4">{label}</span>
                  )}
                </Tab>
              );
            })}
          </ul>
          <Underline
            refs={stepRefs}
            activeTab={currentStepIndex}
            finishAnimating={() => dispatch(setIsAnimating(false))}
            animating={isAnimating}
          />
        </div>
        {panels.map((PanelComponent, index) => {
          if (index !== currentStepIndex) {
            return null;
          }

          return (
            <div key={index} role="tabpanel">
              <PanelComponent currentStepNumber={currentStepIndex + 1} />
            </div>
          );
        })}
      </div>

      {hasStickyElements && (
        <div css={[tw`sticky z-50 bottom-2`, showBasketPay ? tw`mt-12` : null]}>
          {showMobileBasket && <MobileBasket />}
          {showBasketAction && (
            <ConditionalWrapper
              condition={hasOnlyStickyBasketAction}
              wrapper={children => <div tw="pt-12">{children}</div>}
            >
              <BasketAction
                isMobile={true}
                buttonNextText={t('$*components.mobileBasket.button', {
                  defaultValue: '$$Dalej ({{price}} {{currencySymbol}})',
                  replace: {
                    price: getIntegerIfIntegerOrFixed(
                      price?.afterDiscount ?? 0
                    ),
                    currencySymbol,
                  },
                })}
              />
            </ConditionalWrapper>
          )}

          {showBasketPay && (
            <BasketPay
              {...(showBasketAction ? { styles: { css: tw`mt-2` } } : {})}
            />
          )}
        </div>
      )}

      {showPreviuos && (
        <ConditionalWrapper
          condition={!hasStickyElements}
          wrapper={children => <div tw="pt-6">{children}</div>}
        >
          <Button
            onClick={handlePrevTab}
            disabled={isPrevDisabled}
            variant="outlined"
            styles={{
              button: tw`justify-center w-full my-2`,
            }}
            data-cy="order-tabs__button--prev"
          >
            {t('$*components.orderTabs.prevButton.label', '$$Poprzedni krok')}
          </Button>
        </ConditionalWrapper>
      )}

      {isUpXl && !isFirstStep && (
        <div tw="flex mt-9 justify-between flex-wrap -mx-4 -mb-2">
          <Button
            onClick={handlePrevTab}
            disabled={isPrevDisabled}
            variant="outlined"
            styles={{
              button: tw`justify-center flex-auto mx-4 my-2 sm:flex-initial`,
            }}
            data-cy="order-tabs__button--prev"
          >
            {t('$*components.orderTabs.prevButton.label', '$$Poprzedni krok')}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default OrderTabs;
