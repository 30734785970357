import { forwardRef } from 'react';
import { css } from '@emotion/react';
import tw from 'twin.macro';

const Tab = forwardRef(
  ({ disabled = false, isActive, children, onClick }, ref) => {
    const twStyle = css`
      ${tw`flex items-center h-12 px-4 py-2 cursor-pointer min-w-max md:(flex-auto h-20 justify-center)`}
      ${disabled && tw`text-gray-300 cursor-default`}
      ${isActive && tw`w-full text-primary md:w-auto`}
    `;

    return (
      <li
        css={twStyle}
        ref={ref}
        onClick={() => !disabled && onClick()}
        role="presentation"
      >
        {children}
      </li>
    );
  }
);

Tab.displayName = 'Tab';

export default Tab;
