import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import {
  changeDeliveryType,
  selectOrderForm,
  setAddress,
  setPickupPoint,
} from '@store/orderForm/orderForm.slice';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import {
  AddressesList,
  ApiLoadingBoundary,
  Basket,
  Box,
  Button,
  DisplayUpXl,
  HoverBasket,
  Tab,
  TabPanel,
  Tabs,
} from '@components';
import {
  selectTabs,
  setIsCurrentValid,
} from '@features/orderTabs/orderTabsSlice';
import {
  useBasketMethods,
  useMediaQuery,
  usePickupPoints,
  useUserAddresses,
} from '@hooks';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { up } from '@utils/screens';
import showToast from '@utils/showToast';
import useValidateOrder from '@views/NewOrder/NewOrderCreatePage/useValidateOrder';

const DiscountBar = dynamic(() => import('./DiscountBar'));

const OrderFormDelivery = ({ currentStepNumber }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: pickupPoints = [] } = usePickupPoints();

  const {
    data: addresses = [],
    isLoading,
    isFetched,
    isError,
    refetch,
  } = useUserAddresses();
  const { updateOrderBasketModify } = useBasketMethods();

  const {
    deliveryOptions: {
      pickUpPoint: isPickUpPointEnabled,
      address: isAddressEnabled,
    } = {},
  } = useAppConfigSelector(selectModuleConfigClientPanel);
  const [activeTabIndex, setActiveTabIndex] = useState(
    isAddressEnabled ? 0 : 1
  );

  const { address, pickupPoint, deliveryType } = useSelector(selectOrderForm);
  const { isValid } = useValidateOrder();
  const { isSetInititalState } = useSelector(selectTabs);

  const isUp620 = useMediaQuery(up('620px'), true);

  useEffect(() => {
    const index = deliveryType === 'ADDRESS' && isAddressEnabled ? 0 : 1;
    handleChangeTab(index);
  }, []);

  useEffect(() => {
    if (
      isFetched &&
      !isEmpty(addresses) &&
      isEmpty(address) &&
      deliveryType === 'ADDRESS'
    ) {
      const defaultAddress = addresses.find(({ isDefault }) => isDefault);
      handleSetAddress(defaultAddress);
      dispatch(setIsCurrentValid());
    }
  }, [isFetched, addresses]);

  useEffect(() => {
    if (isFetched && isSetInititalState) {
      dispatch(setIsCurrentValid(isValid));
    }
  }, [isFetched, isValid, isSetInititalState]);

  const handleSetAddress = address => {
    dispatch(setAddress(address));

    updateOrderBasketModify({
      payload: {
        delivery: {
          address: address['@id'],
        },
      },
      stepNumber: currentStepNumber,
    });
  };

  const handleSetPickupPoint = pickupPoint => {
    dispatch(setPickupPoint(pickupPoint));

    updateOrderBasketModify({
      payload: {
        delivery: {
          pickUpPoint: pickupPoint['@id'],
        },
      },
      stepNumber: currentStepNumber,
    });
  };

  const handleSuccessAddAddress = address => {
    handleSetAddress(address);
  };

  const handleSuccessUpdateAddress = address => {
    handleSetAddress(address);
  };

  const handleChangeTab = index => {
    const deliveryType = {
      0: 'ADDRESS',
      1: 'PICKUP_POINT',
    };

    setActiveTabIndex(index);
    dispatch(changeDeliveryType(deliveryType[index]));
  };

  return (
    <div tw="flex justify-end">
      <div className="col--main-center">
        <Box styles={{ css: tw`relative` }}>
          <Tabs activeTabIndex={activeTabIndex} onChange={handleChangeTab}>
            {isAddressEnabled && (
              <Tab
                label={t(
                  '$*newOrderCreatePage.orderForm.delivery.addressDelivery',
                  '$$Dostawa na adres'
                )}
                index={0}
                data-cy="tab-delivery--address"
              />
            )}
            {isPickUpPointEnabled && (
              <Tab
                label={t(
                  '$*newOrderCreatePage.orderForm.delivery.pickupAtPoint',
                  '$$Odbiór w punkcie'
                )}
                index={1}
                data-cy="tab-delivery--pickup-point"
              />
            )}
          </Tabs>
          {isAddressEnabled && (
            <TabPanel activeTabIndex={activeTabIndex} index={0}>
              <div tw="pt-3">
                <ApiLoadingBoundary
                  isError={isError}
                  isLoading={isLoading}
                  refetch={refetch}
                >
                  <AddressesList
                    isFetched={isFetched}
                    addresses={addresses}
                    canAddNewAddress={true}
                    onClickAddress={address => {
                      if (!address?.zone) {
                        return showToast(
                          t(
                            '$*newOrderCreatePage.orderForm.delivery.addressIsNotAvaliable',
                            '$$Wybrany adres jest nieobsługiwany, proszę wybrać inny.'
                          )
                        );
                      }
                      handleSetAddress(address);
                    }}
                    selectedAddresses={address?.id ? [address?.id] : []}
                    onSuccessUpdateAddress={handleSuccessUpdateAddress}
                    onSuccessAddAddress={handleSuccessAddAddress}
                    styles={{
                      address: {
                        wrapper: css`
                          ${isUp620 && 'max-width: 50%;'}
                        `,
                      },
                    }}
                  />
                </ApiLoadingBoundary>
              </div>
            </TabPanel>
          )}
          {isPickUpPointEnabled && (
            <TabPanel activeTabIndex={activeTabIndex} index={1}>
              <div tw="pt-3">
                <p>
                  {t(
                    '$*newOrderCreatePage.orderForm.delivery.pickupDiscount',
                    '$$Skorzystaj z rabatu za odbiór w punkcie'
                  )}
                </p>
                <div tw="-m-2">
                  {pickupPoints.map(item => {
                    const isSelected = pickupPoint?.['@id'] === item['@id'];

                    return (
                      <div key={item.id} tw="p-2">
                        <Button
                          variant="outlined"
                          fullWidth={true}
                          onClick={() => {
                            handleSetPickupPoint(item);
                          }}
                          css={[isSelected && tw`text-white bg-primary`]}
                          data-cy="pickup-point"
                        >
                          {item.value}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </TabPanel>
          )}
        </Box>
      </div>
      <DisplayUpXl>
        <div className="col--main-right">
          <div tw="sticky top-28">
            <Basket />
            <DiscountBar>
              {(children, isSomeThingToRender) =>
                isSomeThingToRender ? (
                  <div tw="bg-white py-4 px-5 rounded-md shadow-lg mt-8 text-sm">
                    {children}
                  </div>
                ) : null
              }
            </DiscountBar>
            <HoverBasket />
          </div>
        </div>
      </DisplayUpXl>
    </div>
  );
};

export default OrderFormDelivery;
