const TABS_SLUGS = {
  PREMIUM: 'premium',
  SUMMARY: 'podsumowanie',
  SELECT_DIET: 'wybierz-diete',
  AUTHORIZATION: 'autoryzacja',
  DELIVERY_DATA: 'dane-dostawy',
  SELECT_DISHES: 'wybierz-dania',
  SELECT_ADDONS: 'wybierz-dodatki',
};

export default TABS_SLUGS;
