import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import { Basket, Box, DisplayDownXl, DisplayUpXl } from '@components';
import BasketSummary from '@components/modules/Basket/BasketSummary';
import { useMediaQuery } from '@hooks';
import { up } from '@utils/screens';

import OrderDetails from './OrderDetails';

const DiscountBar = dynamic(() => import('../DiscountBar'));

const OrderFormSummary = ({ currentStepNumber }) => {
  const { t } = useTranslation();
  const isUpXl = useMediaQuery(up('xl'), true);

  return (
    <div tw="flex justify-end">
      <div className="col--main-center">
        <Box styles={{ css: tw`relative` }}>
          <h2 className="h3">
            {t('$*newOrderCreatePage.orderForm.summary.title', '$$Płatność')}
          </h2>
          <BasketSummary
            currentStepNumber={currentStepNumber}
            showBasketPay={isUpXl}
          />
        </Box>
        <DisplayDownXl>
          <Basket styles={{ box: { css: tw`mt-8 mb-0` } }} />
          <DiscountBar>
            {(children, isSomeThingToRender) =>
              isSomeThingToRender ? (
                <div tw="bg-white py-4 px-5 rounded-md shadow-lg mt-8 text-sm">
                  {children}
                </div>
              ) : null
            }
          </DiscountBar>
          <OrderDetails
            styles={{ wrapper: { css: tw`mt-8`, mode: 'replace' } }}
          />
        </DisplayDownXl>
      </div>
      <DisplayUpXl>
        <div className="col--main-right">
          <div tw="sticky top-28">
            <OrderDetails />
            <Basket />
            <DiscountBar>
              {(children, isSomeThingToRender) =>
                isSomeThingToRender ? (
                  <div tw="bg-white py-4 px-5 rounded-md shadow-lg mt-8 text-sm">
                    {children}
                  </div>
                ) : null
              }
            </DiscountBar>
          </div>
        </div>
      </DisplayUpXl>
    </div>
  );
};

export default OrderFormSummary;
