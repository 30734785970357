import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';

import {
  selectModuleAddons,
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { useAppContext } from '@views/App/App.context';

import TABS_SLUGS from './constants/TabsSlugs';
import {
  OrderFormCustomer,
  OrderFormDelivery,
  OrderFormSummary,
} from './components';

const OrderFormDishes = dynamic(() => import('./components/Dishes/Dishes'));
const OrderFormAddons = dynamic(() => import('./components/Addons'));
const OrderFormPremium = dynamic(() => import('./components/Premium'));
const OrderFormDietsFull = dynamic(() =>
  import('./components/Diets/Full/DietsFull.container')
);
const OrderFormDietsMini = dynamic(() =>
  import('./components/Diets/Mini/Diets.container')
);

const useTabsConfig = () => {
  const { t } = useTranslation();

  const { isShop, isDietMini, isDietFull } = useAppContext();

  const { addToOrder } = useAppConfigSelector(selectModuleAddons);
  const { changeMenuMode } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const tabs = useMemo(() => {
    const commonTabs = [
      ...(!isAuthenticated
        ? [
            {
              id: 4,
              label: t('$*orderFormTabs.customer', '$$Dane klienta'),
              panel: OrderFormCustomer,
              slug: TABS_SLUGS.AUTHORIZATION,
            },
          ]
        : []),
      {
        id: 5,
        label: t('$*orderFormTabs.delivery', '$$Dane dostawy'),
        panel: OrderFormDelivery,
        slug: TABS_SLUGS.DELIVERY_DATA,
      },
      {
        id: 6,
        label: t('$*orderFormTabs.summary', '$$Podsumowanie'),
        panel: OrderFormSummary,
        slug: TABS_SLUGS.SUMMARY,
      },
    ];

    const shopModeTabs = [
      {
        id: 1,
        label: t('$*orderFormTabs.selectDishes', '$$Wybierz dania'),
        panel: OrderFormDishes,
        slug: TABS_SLUGS.SELECT_DISHES,
      },
    ];

    const dietFullModeTabs = [
      {
        id: 1,
        label: t('$*orderFormTabs.selectDiet', '$$Wybierz diete'),
        panel: OrderFormDietsFull,
        slug: TABS_SLUGS.SELECT_DIET,
      },
      ...(addToOrder && !isShop
        ? [
            {
              id: 2,
              label: t('$*orderFormTabs.selectAddons', '$$Dodatki'),
              panel: OrderFormAddons,
              slug: TABS_SLUGS.SELECT_ADDONS,
            },
          ]
        : []),
      ...(changeMenuMode === 'PREMIUM'
        ? [
            {
              id: 3,
              label: t('$*orderFormTabs.selectPremium', '$$Premium'),
              panel: OrderFormPremium,
              slug: TABS_SLUGS.PREMIUM,
            },
          ]
        : []),
    ];

    const dietMiniModeTabs = [
      {
        id: 1,
        label: t('$*orderFormTabs.selectDiet', '$$Wybierz diete'),
        panel: OrderFormDietsMini,
        slug: 'wybierz-diete',
      },
    ];

    return [
      ...(isDietFull ? dietFullModeTabs : []),
      ...(isDietMini ? dietMiniModeTabs : []),
      ...(isShop ? shopModeTabs : []),
      ...commonTabs,
    ];
  }, [
    addToOrder,
    isAuthenticated,
    isDietFull,
    isDietMini,
    isShop,
    changeMenuMode,
    t,
  ]);

  return tabs;
};

export default useTabsConfig;
