import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { dehydrate, QueryClient } from 'react-query';

import { DefaultLayout } from '@components';
import withApiTranslations from '@components/HOC/withApiTranslations';
import { getOrderForm } from '@hooks/orders/useOrderForm';
import NewOrderCreatePage from '@views/NewOrder/NewOrderCreatePage/NewOrderCreatePage.container';

export const getServerSideProps = withApiTranslations(async ({ locale }) => {
  const queryClient = new QueryClient();
  const response = await getOrderForm();
  await queryClient.prefetchQuery('orderForm', () => response);

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      basketKey: 'basketNewOrder',
      dehydratedState: dehydrate(queryClient),
    },
  };
});

const LayoutWithProps = ({ children }) => {
  const styles = { content: { mode: 'replace', css: null } };

  return <DefaultLayout styles={styles}>{children}</DefaultLayout>;
};

NewOrderCreatePage.Layout = LayoutWithProps;

export default NewOrderCreatePage;
