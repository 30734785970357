import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import {
  selectOrderForm,
  setUserProceed,
} from '@store/orderForm/orderForm.slice';
import isEmpty from 'lodash/isEmpty';

// import queryString from 'query-string';
import { Banner, Seo } from '@components';
import OrderTabs from '@features/orderTabs/OrderTabs';
import { selectTabs } from '@features/orderTabs/orderTabsSlice';
import { useBasketMethods, useOrderForm } from '@hooks';

import useTabsConfig from './useTabsConfig';

const NewOrderPageCreate = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const params = router.query;
  const dispatch = useDispatch();

  const { data: { banner = {} } = {} } = useOrderForm({
    enabled: false,
  });
  const { currentStepIndex } = useSelector(selectTabs);
  const { userProceed } = useSelector(selectOrderForm);

  const {
    basketQuery: { data: basket = {} },
    commonSetDiscountCode,
    initBasket,
  } = useBasketMethods();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (currentStepIndex > 0) {
      dispatch(setUserProceed());
    }
  }, [currentStepIndex]);

  useEffect(() => {
    initBasket();

    const $body = document.querySelector('body');
    $body.classList.add('page--new-order');

    return () => {
      $body.classList.remove('page--new-order');
    };
  }, []);

  // const setOrderFromParams = () => {
  //   WORK 100% but is ugly url => encodeURIComponent(JSON.stringify(items))
  //   const queryStringOptions = {
  //     arrayFormat: 'bracket-separator',
  //     arrayFormatSeparator: ',',
  //   };
  //   const extractedString = queryString.extract(router.asPath);
  //   const parsedParams = queryString.parse(extractedString, queryStringOptions);
  //   const dietParamsKeys = [
  //     'addon',
  //     'calorific',
  //     'deliveryDates',
  //     'diet',
  //     'dietLength',
  //     'firstDeliveryDay',
  //     'optionChangeMenu',
  //     'package',
  //     'paymentMode',
  //     'testMode',
  //     'useEcoContainers',
  //     'variant',
  //   ];
  //   const reduceParamsByKeys = paramsKeys => {
  //     return Object.entries(parsedParams).reduce(
  //       (acc, [key, value]) => ({
  //         ...acc,
  //         ...(paramsKeys.includes(key) ? { [key]: value } : {}),
  //       }),
  //       {}
  //     );
  //   };
  //   const useDietParamsKeys = reduceParamsByKeys(dietParamsKeys);
  //   const basketParamsKeys = [
  //     'referenceCode',
  //     'discountCode',
  //     'useMoneyBox',
  //     'paymentMethod',
  //   ];
  //   const useBasketParamsKeys = reduceParamsByKeys(basketParamsKeys);
  // };

  useEffect(() => {
    if (isAuthenticated && basket?.id) {
      const referenceCode = params?.reference_code;
      if (!isEmpty(referenceCode)) {
        commonSetDiscountCode(referenceCode);
      }
    }
  }, [isAuthenticated, basket.id]);

  // useEffect(() => {
  //   // fetchAbandonedBasket
  //   // if(abandonedBasket)
  //   // restoreBasket () => saveTo order redux.state
  // }, [])

  const tabsConfig = useTabsConfig();

  return (
    <Fragment>
      <Seo pageName={t('$*newOrderCreatePage.pageName', '$$Nowe zamówienie')} />
      <Banner
        banner={banner}
        isDisabled={currentStepIndex > 0 || userProceed}
      />

      <h1 tw="hidden">
        {t('$*newOrderCreatePage.title', '$$Nowe zamówienie')}
      </h1>
      <div tw="pt-8 pb-24">
        <div className="container">
          <OrderTabs
            // eslint-disable-next-line no-unused-vars
            tabs={tabsConfig.map(({ panel, ...rest }) => rest)}
            panels={tabsConfig.map(({ panel }) => panel)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default NewOrderPageCreate;
