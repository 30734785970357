import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { selectors as basketNewOrderSelectors } from '@store/basket/basketNewOrder.slice';
import { selectOrderForm } from '@store/orderForm/orderForm.slice';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import {
  Clock2Icon,
  ExclamationIcon,
  MapMarkerIcon,
  PiggyBankIcon,
  PriceTagIcon,
} from '@assets/icons';
import { AddressLineInfo, Box, PriceWrapper, Tag, Tooltip } from '@components';
import BasketDiscountTooltip from '@components/modules/Basket/BasketDiscountTooltip';
import { useBasketMethods } from '@hooks';
import {
  selectModuleMoneyBox,
  useAppConfigSelector,
} from '@hooks/app/useAppConfig';
import { transformDeliveryHourToString } from '@services/Api.service';
import { format } from '@services/Date.service';
import { checkImageExists, isFalsify } from '@utils/helpers';
import { cssMerge } from '@utils/styleHelpers';
import { useAppContext } from '@views/App/App.context';

const OrderDetails = ({ styles = {} }) => {
  const { t } = useTranslation();
  const { isShop } = useAppContext();
  const { address, deliveryType, pickupPoint } = useSelector(selectOrderForm);
  const { orderDetails } = useSelector(basketNewOrderSelectors.selectBasket);
  const { icon, pluralNameGenitive, pluralNameDenominator } =
    useAppConfigSelector(selectModuleMoneyBox);

  const [isExistImage, setIsExistImage] = useState(false);

  const {
    basketQuery: { data: basket = {} },
  } = useBasketMethods();
  const hasDiscountParts = !isEmpty(basket?.price?.discountParts);

  useEffect(() => {
    checkImageExists(icon)
      .then(setIsExistImage)
      .catch(() => {
        setIsExistImage(false);
      });
  }, [icon]);

  const isfirstAndLastDeliveryDayTheSame =
    orderDetails.summary.firstDeliveryDay ===
    orderDetails.summary.lastDeliveryDay;

  const allBonuses = Object.values(basket?.availableBonuses ?? {}).reduce(
    (acc, value) => (acc += value),
    0
  );

  const renderDates = () => {
    const DeliveryHours = () => {
      const deliveryHourString = transformDeliveryHourToString({
        hour: address?.selectedDeliveryHour,
        translations: {
          bothHours: {
            from: t('$*common.deliveryHours.between', '$$Pomiędzy'),
            connector: '',
            to: t('$*common.deliveryHours.betweenA', '$$a'),
          },
          singularHour: { from: t('$*common.from'), to: t('$*common.to') },
        },
        t,
      });

      if (deliveryType === 'ADDRESS' && deliveryHourString) {
        return <div>{deliveryHourString}</div>;
      }

      return null;
    };

    if (isfirstAndLastDeliveryDayTheSame) {
      return (
        <div>
          <div>
            {orderDetails.summary.firstDeliveryDay
              ? format(
                  new Date(orderDetails.summary.firstDeliveryDay),
                  'EEEE, dd MMMM yyyy'
                )
              : null}
          </div>
          <DeliveryHours />
        </div>
      );
    }

    return (
      <div>
        <div>
          {orderDetails.summary.firstDeliveryDay
            ? format(
                new Date(orderDetails.summary.firstDeliveryDay),
                'EEEE, dd MMMM yyyy'
              )
            : null}
          <br />
          {'- '}
          {orderDetails.summary.lastDeliveryDay
            ? format(
                new Date(orderDetails.summary.lastDeliveryDay),
                'EEEE, dd MMMM yyyy'
              )
            : null}
        </div>
        <DeliveryHours />
      </div>
    );
  };

  const twWrapperStyle = cssMerge({
    defaultCss: tw`mb-8`,
    ...styles?.wrapper,
  });

  return (
    <Box size="md" styles={{ css: twWrapperStyle }}>
      <h3 className="h3" tw="pb-1.5 mb-4 border-b border-gray-200">
        {t(
          '$*newOrderCreatePage.orderForm.summary.orderDetails',
          '$$Szczegóły zamówienia'
        )}
      </h3>

      <div tw="-m-1 mb-3">
        {isShop && (
          <Tag
            label={t(
              '$*newOrderCreatePage.orderForm.summary.orderDetails.daysCount',
              {
                defaultValue: '$${{ daysCount }} dni',
                replace: {
                  daysCount: orderDetails.summary.days,
                },
              }
            )}
            variant="outline"
            styles={{
              css: tw`m-1`,
            }}
          />
        )}
        {orderDetails.summary.dishes > 0 && (
          <Tag
            label={t(
              '$*newOrderCreatePage.orderForm.summary.orderDetails.dishesCount',
              {
                defaultValue: '$${{ dishesCount }} dań',
                replace: {
                  dishesCount: orderDetails.summary.dishes,
                },
              }
            )}
            variant="outline"
            styles={{
              css: tw`m-1`,
            }}
          />
        )}
        {orderDetails.summary.addons > 0 && (
          <Tag
            label={t(
              '$*newOrderCreatePage.orderForm.summary.orderDetails.addonsCount',
              {
                defaultValue: '$${{ addonsCount }} dodatków',
                replace: {
                  addonsCount: orderDetails.summary.addons,
                },
              }
            )}
            variant="outline"
            styles={{
              css: tw`m-1`,
            }}
          />
        )}
      </div>

      <div tw="-my-2">
        <div tw="flex py-2 text-sm">
          <div tw="w-5 mr-4">
            <MapMarkerIcon
              tw="h-auto mt-1"
              css={css`
                width: 18px;
              `}
            />
          </div>
          {deliveryType === 'ADDRESS' && (
            <div>
              <AddressLineInfo {...address} />
            </div>
          )}

          {deliveryType === 'PICKUP_POINT' && <div>{pickupPoint.value}</div>}
        </div>

        {deliveryType === 'ADDRESS' && address?.selectedDeliveryHour && (
          <div
            css={[
              tw`flex py-2 text-sm`,
              isfirstAndLastDeliveryDayTheSame && tw`items-center`,
            ]}
          >
            <div tw="w-5 mr-4">
              <Clock2Icon
                css={[
                  tw`w-full h-auto`,
                  !isfirstAndLastDeliveryDayTheSame && tw`mt-1.5`,
                ]}
              />
            </div>
            {renderDates()}
          </div>
        )}

        <div tw="flex py-2 text-sm items-center">
          <div tw="w-5 mr-4">
            <PriceTagIcon tw="w-full h-auto mt-0.5" />
          </div>
          <div tw="mr-2">
            {t('$*newOrderCreatePage.orderForm.summary.toPay', '$$Do zapłaty')}
          </div>

          {hasDiscountParts && (
            <BasketDiscountTooltip>
              <span>
                <ExclamationIcon tw="w-4 cursor-pointer" />
              </span>
            </BasketDiscountTooltip>
          )}
          <PriceWrapper
            beforeDiscount={basket?.price?.beforeDiscount}
            afterDiscount={basket?.price?.afterDiscount}
            styles={{ wrapper: { css: tw`ml-auto` } }}
          />
        </div>

        {!isFalsify(basket?.availableBonuses) && (
          <div tw="flex py-2 text-sm items-center">
            <div tw="w-5 h-5 mr-4">
              {isExistImage ? (
                <div
                  css={css`
                    background-image: url(${icon});
                  `}
                  tw="w-full h-full bg-center bg-cover"
                />
              ) : (
                <PiggyBankIcon tw="w-full h-auto" />
              )}
            </div>
            {t('$*newOrderCreatePage.orderForm.summary.gainPoints', {
              defaultValue: '$$Zyskasz {{points}}',
              replace: {
                points:
                  pluralNameGenitive || t('$*common.pointsLiteral', 'punkty'),
              },
            })}

            <Tooltip
              content={
                <div tw="p-2 text-xs -my-3">
                  <div tw="flex justify-between py-3">
                    <div tw="flex-initial">
                      {t(
                        '$*newOrderCreatePage.orderForm.summary.pointsForOrder',
                        {
                          defaultValue:
                            '$$Dokonanie tego zamówienia pozwoli Ci zyskać kolejne {{points}} do Twojej skarbonki!',
                          replace: {
                            points:
                              pluralNameDenominator ||
                              t('$*common.pointsLiteral', 'punkty'),
                          },
                        }
                      )}
                    </div>
                    <div tw="flex-shrink-0">{`+${
                      basket?.availableBonuses?.AFTER_PAYMENT ?? 0
                    } ${
                      pluralNameGenitive ||
                      t('$*common.pointsLiteral', 'punkty')
                    }`}</div>
                  </div>
                </div>
              }
              css={{ minWidth: '300px' }}
            >
              <div tw="ml-3">
                <ExclamationIcon tw="w-4 cursor-pointer" />
              </div>
            </Tooltip>
            <div tw="ml-auto">{`+${allBonuses} ${
              pluralNameGenitive || t('$*common.pointsLiteral', 'punkty')
            }`}</div>
          </div>
        )}
      </div>
    </Box>
  );
};

export default OrderDetails;
